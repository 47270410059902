import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Login from "./Login";
import PicturesView from "./PicturesView";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { logedIn: false, pictures: [], identifier: "" };
    this.onAuthenticated = this.onAuthenticated.bind(this);
    this.onLogOut = this.onLogOut.bind(this);
  }

  onLogOut() {
    this.setState(() => ({ logedIn: false, identifier: "" }));
  }

  onAuthenticated(pictures, identifier) {
    this.setState(() => ({
      logedIn: true,
      pictures: pictures,
      identifier: identifier,
    }));
  }

  render() {
    return (
      <Container fluid="md">
        <Row>
          <Col>
            <img
              alt="logo playland"
              src={process.env.PUBLIC_URL + "/logo.png"}
              className="app-logo"
            />
          </Col>
        </Row>
        {this.state.logedIn ? (
          <PicturesView
            pictures={this.state.pictures}
            identifier={this.state.identifier}
            onLogOut={this.onLogOut}
          />
        ) : (
          <Login onAuthenticated={this.onAuthenticated} />
        )}
      </Container>
    );
  }
}

export default Gallery;
